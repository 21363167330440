import React from "react";
import "./Footer.scss";

export default function Footer() {
  return (
    <div className="footer d-flex p-1 position-relative w-100">
      <div className="footer__crater__tag d-flex justify-content-center">
        Created By Vineeth Rai
      </div>
    </div>
  );
}
